const state = {
  clinicUtilisation: {
    startDate: null,
    endDate: null,
  },
  passFailData: {
    startDate: null,
    endDate: null,
  },
  slaData: {
    startDate: null,
  },
};

const getters = {
  getClinicUtilisation: (state) => state.clinicUtilisation,
};

const actions = {
  setClinicUtilisation({ commit }, params) {
    commit("updateClinicUtilisation", params);
  },
};

const mutations = {
  updateClinicUtilisation(state, params) {
    state.clinicUtilisation = params;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const state = {
  lastLayout: null,
};

const getters = {
  getLastLayout: (state) => state.lastLayout,
};

const actions = {
  setLastLayout({ commit }, layout) {
    commit("updateLastLayout", layout);
  },
};

const mutations = {
  updateLastLayout(state, layout) {
    state.lastLayout = layout;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

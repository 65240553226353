import ApiService from "./api.service";

const UserService = {
  get(query) {
    return ApiService.query("users", query);
  },
  getSingle(user) {
    return ApiService.get(`users/${user.id}`);
  },
  post(user) {
    return ApiService.post("users", user);
  },
  put(user) {
    return ApiService.put(`users/${user.id}`, user);
  },
  delete(user) {
    return ApiService.delete(`users/${user.id}`);
  },
  getUserRoles() {
    return ApiService.get("users/roles");
  },
  reset(user) {
    return ApiService.post(`users/reset/${user.id}`);
  },
  removeMfa(user) {
    return ApiService.delete(`users/mfa/${user.id}`);
  },
  restoreUser(user) {
    return ApiService.post(`users/restore/${user.id}`);
  },
  getInactive(query) {
    return ApiService.query("users/inactive", query);
  },
  getDeleted(query) {
    return ApiService.query("users/deleted", query);
  },
  reactivateUser(id) {
    return ApiService.put(`users/reactivate/${id}`);
  },
};

export default UserService;
